  .root {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto 1fr auto auto;
    height: 32px;
    padding-inline-end: 8px;
    width: 250px;
  }
  .app {
    height: 100%;
  }

  .app ul {
    list-style-type: none;
    padding-left: 10px
  }

  .app ul li:has(+ div) {
    background-color: rgba(228, 97, 15, 0.08);
  }

  .expandIconWrapper {
    align-items: center;
    font-size: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
  }
  
  .expandIconWrapper.isOpen {
    transform: rotate(90deg);
  }
  
  .labelGridItem {
    padding-inline-start: 8px;
    white-space: nowrap;
    cursor: pointer;
    width: max-content;
    display: flex
  }
  
  .textField {
    padding: 0px;
  }

  /* This solves for a problem when a fast hover happens, onmouseleave event doesn't trigger and icons are left visible unnecessarily */
  .itemWithOptions:not(:hover) .moreIcon { 
    opacity : 0 ;
  }

  .itemWithOptions {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer
  }

  .actionButton {
    padding: 0 4px;
  }

  .treeRoot {
    height: 100%;
    margin: 0;
    padding: 0
  }
  
  .draggingSource {
    opacity: 0.3;
  }
  
  .dropTarget {
    background-color: #e8f0fe;
  }
  